import React, { Component } from 'react';
import Logo_Text from '../assets/images/eSpotz_logo.png';
import { profile } from '../utils/common.utils';
import { getAvatarForLetter } from '../utils/images.utils';
import AuthService from '../services/auth.service';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const authApi = new AuthService();

    const logout = () => {
        authApi.signOut()
            .then(() => {
                localStorage.removeItem('user');
                navigate('/login');
            })
            .catch((err) => {
                console.error(err);
            });
    };

    // Get default avatar (SVG as a data URI)
    const defaultImage = (() => {
        const letter = profile()?.name?.charAt(0).toLowerCase() || 'A';
        const svg = getAvatarForLetter(letter);
        return `data:image/svg+xml;base64,${btoa(svg)}`;
    })();

    return (
        <div className="app-header header-shadow">
            {/* Logo */}
            <div className="app-header__logo d-flex justify-content-center">
                <span className="brand-title">
                    <img className="company-title-img" src={Logo_Text} alt="Logo" />
                </span>
            </div>

            {/* Mobile Menu */}
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>

            {/* Dropdown Menu */}
            <div className="app-header__menu">
                <Dropdown>
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                        }}
                    >
                        <img
                            src={defaultImage}
                            className="rounded-circle"
                            height="40"
                            width="40"
                            alt="User Avatar"
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => navigate('/user/profile')}>
                            Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate('/user/change-password')}>
                            Change Password
                        </Dropdown.Item>
                        <Dropdown.Item onClick={logout}>
                            Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {/* Content */}
            <div className="app-header__content">
                <div className="app-header-right">
                    <Dropdown>
                        <Dropdown.Toggle
                            id="dropdown-basic"
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                outline: 'none',
                            }}
                        >
                            <img
                                src={defaultImage}
                                className="rounded-circle"
                                height="40"
                                width="40"
                                alt="User Avatar"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => navigate('/user/profile')}>
                                Profile
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => navigate('/user/change-password')}>
                                Change Password
                            </Dropdown.Item>
                            <Dropdown.Item onClick={logout}>
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default Header;

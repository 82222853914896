import teams from '../assets/images/dashboard/teams.png';
import operators from '../assets/images/dashboard/operators.png';
import denUsers from '../assets/images/dashboard/den-users.png';
import espotzUsers from '../assets/images/dashboard/espots-user.png';

import regular from '../assets/images/dashboard/regular.png';
import average from '../assets/images/dashboard/time.png';
import raised from '../assets/images/dashboard/raised.png';
import premium from '../assets/images/dashboard/premium.png';


import BettingIcon from '../assets/images/icons/Betting.png';
import CommissionIcon from '../assets/images/icons/Commission.png';
import DashboardIcon from '../assets/images/icons/Dashboard.png';
import DenIcon from '../assets/images/icons/Den.png';
import CurrancyIcon from '../assets/images/icons/EC-Currency.png';
import RequestIcon from '../assets/images/icons/Feature-Requests.png';
import FormsIcon from '../assets/images/icons/Forms.png';
import GameIcon from '../assets/images/icons/Games.png';
import NotificationIcon from '../assets/images/icons/Notifications.png';
import RulesIcon from '../assets/images/icons/Rules.png';
import ConditionsIcon from '../assets/images/icons/Terms-Conditions.png';
import CancellationIcon from '../assets/images/icons/Cancellation.png';
import UsersIcon from '../assets/images/icons/Users.png';
import VerificationIcon from '../assets/images/icons/Verification.png';
import WalletIcon from '../assets/images/icons/Wallet.png';
import WebsiteIcon from '../assets/images/icons/Website.png';
import BugIcon from '../assets/images/icons/bug.png';



export const CARDS = {
    ESPOTZ_USERS: espotzUsers,
    TEAMS : operators,
    OPERATORS : teams,
    DEN_USERS : denUsers,
    
    REGULAR: regular,
    PREMIUM : premium,
    DISPUTE : raised,
    AVERAGE : average
    
}

export const SIDEBAR = {
    DASHBOARD : DashboardIcon, 
    USERS : UsersIcon,
    VERIFICATION : VerificationIcon,
    GAMES : GameIcon,
    WEBSITE : WebsiteIcon,
    WALLET : WalletIcon,
    COMMISSION : CommissionIcon,
    NOTIFICATIONS : NotificationIcon,
    REQUESTS : RequestIcon,
    CANCELLATION : CancellationIcon,
    FORMS : FormsIcon,
    BETTING : BettingIcon, 
    CURRENCY : CurrancyIcon,
    RULES : RulesIcon,
    CONDITIONS : ConditionsIcon,
    DEN : DenIcon,
    BUG : BugIcon
}

export const getAvatarForLetter = (letter) => {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="48" fill="#3498db" />
        <text x="50" y="60" text-anchor="middle" font-size="40" fill="#ffffff" font-family="Arial, sans-serif" dy=".3em">
          ${letter.toUpperCase()}
        </text>
      </svg>
    `;
  };
  
 
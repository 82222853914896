
import React, { Component } from 'react';
import { HEADING } from '../../utils/strings.utils';
import { getGamesDetails, routeTo, updateGameDetails, updateLoader, uploadSingleImg } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { AiFillCamera, AiFillCloseCircle } from "react-icons/ai";

import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';

const GAMES_ENUM = {
    ALL_GAMES: 'isGameEnabled',
    POINT_MATRIX: 'pointMatrix',
    BETTING: 'isBettingEnabled',
    ENTRY_FEE: 'isEntryFeeEnabled',
    PRICE_POOL: 'isPrizePoolEnabled',
};

export default class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          gameDetails: null,
          selectedGameId: null,
        };
    }
  
    componentDidMount(){
      getGamesDetails(this.setGamesDetails);
    }

  

  
    setGamesDetails = (gameDetails) =>{
      if(gameDetails){
        this.setState({
          gameDetails: gameDetails,
          selectedGameId: null
        });
      }

    }

    triggerInputFile = (index) => {
      this.setState({
        selectedGameId : index
      });
      this.fileInput.click();
    };

    onImageChange = event => {
      this.setState({
        invalidImage: null,
      });
  
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        
  
        if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
          toast.warning('Please select a valid image type.');
          return false;
        }
        const formData = new FormData();
        formData.append('file', event.target.files[0])
        console.log("---------- formData :: ",formData)
        uploadSingleImg(formData, this.setUpdatedImage)
      }
    
    }

    setUpdatedImage = (data) => {
      updateGameDetails(this.state.selectedGameId, {logo : data}, this.setGamesDetails);
    }


    render() {

        console.log("------------active page :: ",this.state.gameDetails)

        if(this.state.gameDetails){

          const gameDetails = this.state.gameDetails; //.filter(game => (game.name.toLowerCase()).includes(this.state.searchParam));
          gameDetails.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          console.log("------------gameDetails :: ",gameDetails)
          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        <h3 className='text-light-white'>{BREADCRUMBS.LANDING_PAGE.HEADING}</h3>
                    </div>
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.LANDING_PAGE.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-0">
                            {/* SUB-HEAD */}
                            <div className="card-header no-background px-3 py-0 my-0">
                                <p className='my-0 sub-heading '>Custom Data For Static/Dynamic Pages</p>
                                <div className="btn-actions-pane-right">
                                  {/* Add content to right side */}
                                </div>
                            </div>
                            
                            <hr className='seperator my-0'/>

                            {/* MAIN_BODY */}
                            <div className='d-flex justify-content-center'>
                              <div className='row p-3' style={{maxWidth: '1150px'}}>
                                {gameDetails.map((game, i) => {
                                    return (
                                      <div className='col-12 col-md-6 col-lg-4 my-2' key={i}>
                                        <label className='custom-label'>{game.name}</label>
                                        <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={this.onImageChange} ref={fileInput => this.fileInput = fileInput} name="" className="profile-picker" />
                                        <div className='d-flex flex-column'>
                                          <div className='camera-container ms-auto'>
                                            <AiFillCamera className='close-icon' onClick={()=>this.triggerInputFile(game._id)}/>
                                          </div>
                                          <img src={game?.logo?.url} alt='' style={{height: '160px', width: 'auto', borderRadius: '10px'}}/>
                                        </div>
                                        
                                      </div>
                                    )
                                })}
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
        }else{
          return null;
        }
       
    }
}
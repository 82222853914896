import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom";

import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import DashboardService from "../services/dashboard.service";
import { toast } from 'material-react-toastify';
import GameService from "../services/game.services";
import WebisteService from "../services/website.service";
import NotificationService from "../services/notification.service";
import FormsService from "../services/forms.service";
import NewFeatureRequest from "../services/featureRequest.service";
import DenService from "../services/den.service";
import BugServices from "../services/bugs.services";
import BettingService from "../services/betting.service";
import VerificationService from "../services/verification.service";
import WalletService from "../services/wallet.service";
import CommissionService from "../services/commission.service";

const dashboardService = new DashboardService();
const gameService = new GameService();
const webisteService = new WebisteService();
const notificationService = new NotificationService();
const formsService = new FormsService();
const featureRequest = new NewFeatureRequest();
const denService = new DenService();
const bugsReport = new BugServices();
const bettingService = new BettingService();
const verificationService = new VerificationService();
const walletService = new WalletService();
const commissionService = new CommissionService();

var redirectTo = null;
var showLoader;

export const saveRouter = (router) => {
    redirectTo = router;
}

export const routeTo = (path, page) => {
    if (redirectTo) {
        redirectTo(path, page);
    }
}

export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let searchParamsArray = useSearchParams()
        let searchParams = Object.fromEntries([...searchParamsArray][0]);
        return (
            <Component
                {...props}
                router={{ location, navigate, params, searchParams }}
            />
        );
    }
    return ComponentWithRouterProp;
}

export const isLoggedIn = () => {
    return !!JSON.parse(localStorage.getItem('user'))?.accessToken
}

export const token = () => {
    return JSON.parse(localStorage.getItem('user'))?.accessToken
}

export const profile = () => {
    return JSON.parse(localStorage.getItem('user'))?.user
}
export const Role = () => {
    return JSON.parse(localStorage.getItem('user'))?.user.role
}

export const uploadSingleImg = async (data, setUpdatedImage) => {
    updateLoader(true)
    axios.post(`${process.env.REACT_APP_API_URL}/public/api/v1/assets/uploadSingleImage`, data).then((response) => {
        setUpdatedImage(response.data.result);
        updateLoader(false)
    }).catch((err: AxiosError) => {
        console.error(err)
        setUpdatedImage(null);
        updateLoader(false)
    });
}

export const getDashboardDetails = async (setDashboardDetails) => {

    updateLoader(true);
    await dashboardService.getDashboardDetails().then(
        (res: AxiosResponse) => {
            var dashboardDetails = res.data['result'];
            console.log("dashboardDetails :: ", dashboardDetails)
            setDashboardDetails(dashboardDetails);
            updateLoader(false);

    }).catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setDashboardDetails(null);
    });
}

export const getTournamentDetails = async (params, setTournamentDetails) => {
    
    updateLoader(true);
    
    await dashboardService.getTournamentDetails(params).then(
        (res: AxiosResponse) => {
            var tournamentDetail = res.data['result'];
            console.log("tournamentDetail :: ",tournamentDetail)
            setTournamentDetails(tournamentDetail,res.data.totalResults);
            updateLoader(false);

    }).catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setTournamentDetails(null,0);
    });
}

// GET GAME DETAILS
export const getGamesDetails = async (setGamesDetails) => {

    updateLoader(true);
    await gameService.getGamesDetails().then(
        (res: AxiosResponse) => {
            var gamesDetails = res.data['result'];
            console.log("gamesDetails :: ", gamesDetails)
            setGamesDetails(gamesDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setGamesDetails(null);
        });
}

// UPDATE GAME DETAILS
export const updateGameDetails = async (id, data, setGamesDetails) => {

    updateLoader(true);
    await gameService.updateGameDetails(id, data).then(
        (res: AxiosResponse) => {
            var gamesDetails = res.data['result'];
            setGamesDetails(gamesDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}

// GET WEB SITE DETAILS
export const getWebsiteDetails = async (param, setwebsiteDetails) => {

    updateLoader(true);
    await webisteService.getWebsiteDetails(param).then(
        (res: AxiosResponse) => {
            var websiteDetails = res.data['result'];
            console.log("websiteDetails :: ", websiteDetails)
            setwebsiteDetails(websiteDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setwebsiteDetails(null);
        });
}

// UPDATE WEB SITE DETAILS
export const updateWebsiteDetails = async (id, data, setwebsiteDetails) => {

    updateLoader(true);
    await webisteService.updateWebsiteDetails(id, data).then(
        (res: AxiosResponse) => {
            var websiteDetails = res.data['result'];
            console.log("updateWebsiteDetails :: ", websiteDetails)
            toast.success("Website details has been updated successfully")
            setwebsiteDetails(websiteDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setwebsiteDetails(null);
        });
}

// GET NOTIFICATIONS DETAILS
export const getNotificationDetails = async (param, setNotificationDetails) => {

    updateLoader(true);
    await notificationService.getNotificationDetails(param).then(
        (res: AxiosResponse) => {
            var notificationDetails = res.data['result'];
            console.log("notificationDetails :: ", notificationDetails)
            setNotificationDetails(notificationDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setNotificationDetails(null);
        });
}

// UPDATE NOTIFICATIONS DETAILS
export const updateNotificationDetails = async (id, data, setNotificationDetails) => {

    updateLoader(true);
    await notificationService.updateNotificationDetails(id, data).then(
        (res: AxiosResponse) => {
            var notificationDetails = res.data['result'];
            console.log("updateNotificationDetails :: ", notificationDetails)
            toast.success("Notifications details has been updated successfully")
            setNotificationDetails(notificationDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setNotificationDetails(null);
        });
}

// GET FORMS DETAILS
export const getFormsDetails = async (setFormsDetails) => {

    updateLoader(true);
    await formsService.getFormsDetails().then(
        (res: AxiosResponse) => {
            var formsDetails = res.data['result'];
            console.log("formsDetails :: ", formsDetails)
            setFormsDetails(formsDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setFormsDetails(null);
        });
}

// UPDATE FORMS DETAILS
export const updateFormsDetails  = async (id, data, setFormsDetails) => {

    updateLoader(true);
    await formsService.updateFormsDetails(id, data).then(
        (res: AxiosResponse) => {
            var formsDetails = res.data['result'];
            console.log("updateFormsDetails: ", formsDetails)
            toast.success("Forms details has been updated successfully")
            setFormsDetails(formsDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setFormsDetails(null);
        });
}
export const getBettingStatements = async (param, setBettingStatements) => {
    
    updateLoader(true);
    await bettingService.getBettingStatements(param).then(
        (res: AxiosResponse) => {
            var bettingStatments = res.data['result'];
            console.log("bettingStatments :: ",bettingStatments)
            setBettingStatements(bettingStatments);
            updateLoader(false);

    }).catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setBettingStatements(null);
    });
}

export const addBettingStatements = async (data, setBettingStatements) => {
    
    updateLoader(true);
    await bettingService.addBettingStatements(data).then(
        (res: AxiosResponse) => {
            var bettingStatments = res.data['result'];
            toast.success("New betting statement added successfully")
            setBettingStatements(bettingStatments);
            updateLoader(false);

    }).catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setBettingStatements(null);
    });
}

// UPDATE BETTING STATEMENT DETAILS
export const updateBettingStatement = async (id, data, setBettingStatements) => {
    
    updateLoader(true);
    await bettingService.updateBettingStatement(id, data).then(
        (res: AxiosResponse) => {
            var bettingStatments = res.data['result'];
            console.log("updateBettingStatments: ",bettingStatments)
            toast.success("Betting statement has been updated successfully")
            setBettingStatements(bettingStatments);
            updateLoader(false);

    }).catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setBettingStatements(null);
    });
}

export const deleteBettingStatement = async (id, setBettingStatements) => {
    updateLoader(true);
    await bettingService.deleteBettingStatements(id).then(
        (res: AxiosResponse) => {
            var bettingStatments = res.data['result'];
            console.log("updateBettingStatments: ",bettingStatments)
            toast.success("Betting statement has been deleted successfully")
            setBettingStatements(bettingStatments);
            updateLoader(false);

    }).catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setBettingStatements(null);
    });
}

export const saveLoader = (updateLoader) => {
    showLoader = updateLoader;
}

export const updateLoader = (val) => {
    if(showLoader){
        showLoader(val);
    }
}

export const errorHandler = (reason, logout?) => {
    console.log("reason :: ", reason);
    if (((reason?.response?.status === 400) || (reason?.response?.status === 422))) {
        toast.error(reason.response.data.error);
    } else if (reason?.response?.status === 401) {
        toast.error("Your session has been expired, please log in again");
        if (logout) {
            logout(true)
        }
    } else {
        toast.error(reason.message);
    }
}

//Get New Feature Requests
export const getFeatureRequests = async (setFeatureRequest) => {
    updateLoader(true);
    await featureRequest.getFeatureRequests().then(
        (res: AxiosResponse) => {
            //console.log("featureRequests :: ", res)
            setFeatureRequest(res.data.result)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setFeatureRequest(null)
        });
}

//Delete Feature Requests
export const deleteFeatureRequests = async (id, setFeatureRequest) => { 
    updateLoader(true);
    await featureRequest.deleteFeatureRequest(id).then(
        (res: AxiosResponse) => {
            //console.log("featureRequests :: ", res)
            setFeatureRequest(res.data.result)
            updateLoader(false);

    }).catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setFeatureRequest(null)
    });
}

//Get Bug Report
export const getBugsReport = async (setBugsReport) => { 
    updateLoader(true);
    await bugsReport.getBugsReport().then(
        (res: AxiosResponse) => {
            //console.log("featureRequests :: ", res)
            setBugsReport(res.data.result)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setBugsReport(null)
        });
}

//Delete Bug Report
export const deleteBugReport = async (id, setBugsReport) => { 
    updateLoader(true);
    await bugsReport.deleteBugsReport(id).then(
        (res: AxiosResponse) => {
            //console.log("featureRequests :: ", res)
            setBugsReport(res.data.result)
            updateLoader(false);

    }).catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setBugsReport(null)
    });
}

//Get Den Details
export const getDenDetails = async (params, setDenDetails) => {
    updateLoader(true);
    await denService.getDenDetails(params).then(
        (res: AxiosResponse) => {
            //console.log("featureRequests :: ", res)
            setDenDetails(res.data.result)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setDenDetails(null)
        });
}

//Get Den Hashtag Details
export const getDenHashTagDetails = async (params, setDenHashTagDetails) => {
    updateLoader(true);
    await denService.getDenHashTagDetails(params).then(
        (res: AxiosResponse) => {
            setDenHashTagDetails(res.data.result)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}

//Add Den Hashtag Details
export const addDenHashTagDetails = async (data, setDenHashTagDetails) => {
    updateLoader(true);
    await denService.addDenHashTagDetails(data).then(
        (res: AxiosResponse) => {
            setDenHashTagDetails(res.data.result)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}

//Update Den Hashtag Details
export const updateDenHashTagDetails = async (gameId, editIndex, data, setDenHashTagDetails) => {
    updateLoader(true);
    await denService.updateDenHashTagDetails(gameId, editIndex, data).then(
        (res: AxiosResponse) => {
            setDenHashTagDetails(res.data.result)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}

//Delete Den Hashtag Details
export const deleteDenHashTagDetails = async (gameId, deleteIndex, setDenHashTagDetails) => {
    updateLoader(true);
    await denService.deleteDenHashTagDetails(gameId, deleteIndex).then(
        (res: AxiosResponse) => {
            setDenHashTagDetails(res.data.result)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}


// Update Den Details
export const updateDenDetails = async (id, data, setDenDetails) => {

    updateLoader(true);
    await denService.updateDenDetails(id, data).then(
        (res: AxiosResponse) => {
            var denDetails = res.data['result'];
            console.log("updateDenDetails: ", denDetails)
            toast.success("Den details has been updated successfully")
            setDenDetails(denDetails);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setDenDetails(null);
        });
}

//Get Betting Details
export const getBettingDetails = async (setBettingDetails) => {
    updateLoader(true);
    await bettingService.getBettingDetails().then(
        (res: AxiosResponse) => {
            //console.log("featureRequests :: ", res)
            setBettingDetails(res.data.result, false)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setBettingDetails(null)
        });
}

// Update Betting Details
export const updateBettingDetails = async (id, data, setBettingDetails) => {

    updateLoader(true);
    await bettingService.updateBettingDetails(id, data).then(
        (res: AxiosResponse) => {
            var betDetails = res.data['result'];
            console.log("updateDenDetails: ", betDetails)
            toast.success("Betting details has been updated successfully")
            setBettingDetails(betDetails, true);
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setBettingDetails(null);
        });
}

//Get Betting Organisation Details
export const getBettingOrgDetails = async (params, setBettingOrgDetails) => {
    updateLoader(true);
    await bettingService.getBetOrgDetails(params).then(
        (res: AxiosResponse) => {
            setBettingOrgDetails(res, false)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setBettingOrgDetails(null);
        });
}

//Get Betting Tournament Details
export const getBetTournamentDetails = async (params, setBettingTourDetails) => {
    updateLoader(true);
    await bettingService.getBetTournamentDetails(params).then(
        (res: AxiosResponse) => {
            setBettingTourDetails(res, false)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
            setBettingTourDetails(null);
        });
}

//Get Wallet Payments Details
export const getWalletPayments = async (type, status, params, setWalletPayments) => {
    updateLoader(true);
    await walletService.getWalletPayments(type, status, params).then(
        (res: AxiosResponse) => {
            setWalletPayments(res.data.result,res.data.totalResults, false)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}

//Approve Or Reject Payment Details
export const approveOrRejectPayment = async (paymentId, isApprove, data, setWalletPayment) => {
    updateLoader(true);
    await walletService.approveOrRejectPayment(paymentId, isApprove, data).then(
        (res: AxiosResponse) => {
            setWalletPayment(res.data.result,res.data.totalResults, false)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}

//Get Commission Details
export const getCommissionDetails = async (setCommissionDetails) => {
    updateLoader(true);
    await commissionService.getCommission().then(
        (res: AxiosResponse) => {
            setCommissionDetails(res.data.result)
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}

export const queryParams = (params) => {
    return Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');
}

export const convertDateTime = (data) => {
    let date = new Date(data)
    return ` ${date.getDate() +
        "/" + (date.getMonth() + 1) +
        "/" + date.getFullYear() +
        " " + date.getHours() +
        ":" + date.getMinutes() +
        ":" + date.getSeconds()}`;
    //     let newDate = new Date(data);
    // let dataArray = newDate.toString().split(' ');
    // return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]} ${dataArray[4]}`;
}

//Update Organisation Betting stats
export const updateOrgBettingStats = async (id,data) => {
    updateLoader(true);
    await verificationService.updateOrg(id, data).then(
        (res: AxiosResponse) => {
            updateLoader(false);

        }).catch((err: AxiosError) => {
            errorHandler(err);
            updateLoader(false);
        });
}

export const getMaxLenght = (key) => {
    console.log(`getMaxLenght key :: ${key}`);
    switch (true) {
        case ((key === 'CLAN_DESCRIPTION') || (key === 'ORG_DESCRIPTION')):
            console.log('limit :::', 2000);
            return 2000;
        case ((key === 'MAX_PARTICIPANTS') || (key === 'QUALIFICATION_THRESHOLD') || (key === 'PLAY_OFF_FORMAT') || (key === 'ENABLE_GRAND_FINALE') || (key === 'MAX_NO_OF_GROUPS')):
            console.log('limit :::', 200);
            return 200;
        case ((key === 'CATEGORY') || (key === 'PARTICIPANTS_TYPE') || (key === 'COMPETITION_TYPE')):
            console.log('limit :::', 200);
            return 200;
        case ((key === 'SET_UP_RULES') || (key === 'SET_UP_BETTING_RULES') || (key === 'SET_UP_DISCLAIMER')):
            console.log('limit :::', 200);
            return 200;
        case ((key === 'BETTING_RULES') || (key === 'TERMS_AND_CONDITIONS') || (key === 'RULES') || (key === 'PRIVACY_POLICY') || (key === 'DISCLAIMER') || (key === 'CONTACT_ESPOTZ') || (key === 'SOCIAL_MEDIA_POLICY') || (key === 'PAYMENTS_OPTIONS')):
            console.log('limit :::', 300);
            return 300;
        case ((key === 'NEWS_HEADING') || (key === 'NEWS_SOURCE')):
            console.log('limit :::', 300);
            return 300;
        case ( key === 'NEWS_SUMMARY' ):
            console.log('limit :::', 1500);
            return 1500;
        default:
            return 300;
    }
}


export const getClanOrgMembers = (members) => {
    //console.log('members ----',members);
    try{
        delete members.creator;
        let totalMembers = 0;
        Object.keys(members).map((key, i) => {
            totalMembers +=  members[key]?.length;
        });
        return totalMembers;
    }catch{
        return 0;
    }
    
}

export const getAllMembersIdFromTeam = (members) => {
    var result = [];
    delete members.captain;
    Object.keys(members).map((key, i) => {
        members[key].forEach((user,i)=>{
            try{
                
                if(typeof user === 'string'){
                    if(user !== profile().id){
                        result.push(user)
                    }
                }else if(user.id !== profile().id){
                    result.push(user.id)
                }
            }catch(err){
                console.log("Error :: ",err);
            }
        });
    });
    // console.log('$$$$', [...new Set(result)]);
    return [...new Set(result)].length;
}

export const getScrollingText = (val) => {
    return <marquee className='marquee-text fw-bold text-uppercase' behavior="scroll" direction="left" scrollamount="3">{val}</marquee>
}

export const getScrollingNormalText = (val) => {
    return <marquee className='marquee-text' behavior="scroll" direction="left" scrollamount="3">{val}</marquee>
}

export const getTeamMembers = (teamData) => {

    //console.log('teamData', teamData);
    var teamMembers = [];
    //console.log('Team Members ::', teamMembers);
    if(teamData.members){
        if(teamData.members.captain && teamData.members.captain.length){
            teamMembers.push(teamData.members.captain)
        }
        
        if(teamData.members.players && teamData.members.players.length){
            teamData.members.players.map(player => teamMembers.push(player))
        }
        
        if(teamData.members.substitutes && teamData.members.substitutes.length){
            teamData.members.substitutes.map(player => teamMembers.push(player))
        }
        
        if(teamData.members.coach && teamData.members.coach.length){
            teamData.members.coach.map(player => teamMembers.push(player))
        }
        
        if(teamData.members.manager && teamData.members.manager.length){
            teamData.members.manager.map(player => teamMembers.push(player))
        }
    }
    
    //console.log('Team Members ::', teamMembers);
    return teamMembers;
}

import React, { Component } from 'react';
import { HEADING, POINTS_RANK, WEBSITE } from '../../utils/strings.utils';
import { getGamesDetails, getMaxLenght, getWebsiteDetails, routeTo, updateWebsiteDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';

import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';

export default class InformationText extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          websiteDetails : null,
          selectedChoice : 0,
          editIndex : -1,
          updateValue : null
        };
    }
  
    componentDidMount(){
      getWebsiteDetails(`type=INFORMATION_TEXT`,this.setwebsiteDetails);
    }

    setwebsiteDetails = (websiteDetails) =>{
      if(websiteDetails){
        websiteDetails = websiteDetails.sort((a, b) => a.subType.toLowerCase().localeCompare(b.subType.toLowerCase()))
        this.setState({
          websiteDetails: websiteDetails,
          editIndex : -1,
          updateValue : null
        });
      }
    }

    onSelectOption = (index) =>{
      this.setState({
        selectedChoice : index
      });
    }


    enableEditOption = (index) =>{
      this.setState({
        editIndex: index
      });
    }

    changeValue = (val) =>{
      this.setState({
        updateValue: (val ? val : 0)
      });
    }

    updateWebsiteDetails = (key) => {

      console.log("--------- ID :: ",this.state.websiteDetails[this.state.selectedChoice]._id);
      if((!this.state.updateValue) || (this.state.updateValue?.trim() === "")){
        toast.warning('Description is required');
        return false;
      }
      if(this.state.updateValue?.length > 2000){
        toast.warning('Max characters limit is  2000');
        return false;
      }

      let data = {};
      data[key] = this.state.updateValue;
      updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, data, this.setwebsiteDetails);
            
    }

    render() {
        const enableEditOption = this.enableEditOption;
        const changeValue = this.changeValue;
        const updateWebsiteDetails = this.updateWebsiteDetails;
        const {editIndex, websiteDetails, selectedChoice} = this.state;

        if(this.state.websiteDetails){
          
          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    {/* MAIN-HEAD */}
                    <div className="flex-grow-1">
                        <h3 className='text-light-white'>{BREADCRUMBS.INFORMATION_TEXT.HEADING}</h3>
                    </div>
                    {/* BRAD-CRUMB */}
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.INFORMATION_TEXT.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            {/* SUB-HEAD */}
                            <div className="card-header no-background p-0 mb-2 ">  Information on <i className="fa fa-info-circle px-2 fs-20 mb-1" aria-hidden="true"/> button (For that field)
                                {/* FILTER */}
                                <div className="btn-actions-pane-right">
                                 
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                                      <b className='px-5'>{WEBSITE.INFORMATION_TEXT[this.state.websiteDetails[this.state.selectedChoice]?.subType]?.NAME}</b>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {this.state.websiteDetails?.map((module,i)=>{
                                        if(this.state.selectedChoice !== i){
                                          return (
                                            <Dropdown.Item key={i} onClick={()=>this.onSelectOption(i)}>
                                              <b className='px-4'>{WEBSITE.INFORMATION_TEXT[module.subType].NAME}</b> 
                                            </Dropdown.Item>
                                          )
                                        }
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                            </div>
                            {/* MAIN_BODY */}
                            <div className='row'>
                              {this.state.websiteDetails[this.state.selectedChoice]?.questions && Object.keys(this.state.websiteDetails[this.state.selectedChoice].questions).map(function(key, index) {

                                  const selectedOption = websiteDetails[selectedChoice].subType;
                                  if(editIndex === index){
                                    return (
                                      <div className='col-12  my-2' key={key}>
                                        <label><span className=' font-bold'>{WEBSITE.INFORMATION_TEXT[selectedOption][key]}</span></label>
                                      
                                        <div className="input-group" >
                                          <input type="text" 
                                            id={key}
                                            onChange={(e)=>changeValue(e.target.value)}
                                            defaultValue={websiteDetails[selectedChoice].questions[key]}
                                            placeholder={`Enter ${WEBSITE.INFORMATION_TEXT[selectedOption][key]} `}
                                            className="form-control transperent-box light-border-around text-gray" 
                                            aria-label="Recipient's username" aria-describedby="basic-addon2"
                                            style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                            maxLength={getMaxLenght(key)}
                                          />
                                          <div className="input-group-append">
                                            <button className="btn edt-btn light-border-around" onClick={()=>updateWebsiteDetails(key)} type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }else{
                                    return (
                                      <div className='col-12  my-2' key={key}>
                                        <label><span className=' font-bold'>{WEBSITE.INFORMATION_TEXT[selectedOption][key]}</span></label>
                                      
                                        <div className="input-group" >
                                          <input type="text" 
                                            id={key}
                                            placeholder={`Enter ${WEBSITE.INFORMATION_TEXT[selectedOption][key]} `}
                                            value={websiteDetails[selectedChoice].questions[key]}
                                            readOnly
                                            className="form-control transperent-box light-border-around text-gray" 
                                            aria-label="Recipient's username" aria-describedby="basic-addon2"
                                            style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                            maxLength={getMaxLenght(key)}
                                          />
                                          <div className="input-group-append">
                                            <button className="btn edt-btn light-border-around" onClick={()=>enableEditOption(index)}  type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                              Edit
                                            </button>
                                          </div>
                                        </div>
                                      
                                      </div>
                                    )
                                  }
                                })
                              }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
        }else{
          return null;
        }
       
    }
}